import Button from '@mui/material/Button'
import Login from '@/components/Login'
import { useRef, useState, useEffect } from 'react'
import Signup from '@/components/Signup'
import { loginUserInfo, addUserInfo, changeIfGuest, setLoadingStatus } from '@/store/loginUserInfo'
import { useSelector, useDispatch } from 'react-redux'
import { getUserInfo } from '@/service/api'
import { Avatar,  Menu, MenuItem, ListItemText } from '@mui/material'

export default function Header() {
  const loginDialog = useRef(null)  
  const signupDialog = useRef(null)
  const dispatch = useDispatch()
  const { userInfo } = useSelector(loginUserInfo)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const callSignup = () => {
    signupDialog.current.open()
    loginDialog.current.close()
  }
  const callLogin = () => {
    loginDialog.current.open()
    signupDialog.current.close()
  }
  const getUser = async () => {
    const token = localStorage.getItem('TOKEN')
    if (!token) return
    const res = await getUserInfo()
    if (res?.data) {
      dispatch(addUserInfo(res?.data))
      dispatch(changeIfGuest(false))
      dispatch(setLoadingStatus(false))
    }
  }
  const logout = () => {
    localStorage.removeItem('TOKEN')
    dispatch(addUserInfo({}))
    dispatch(changeIfGuest(true))
    handleMenuClose()
  }
  useEffect(() => {
    const token = localStorage.getItem('TOKEN')
    if (token) {
      getUser()
    } else {
      dispatch(setLoadingStatus(false))
    }
  }, [])
  
  return (
    <>
      <header className="bg-[#131313] p-4 mb-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50 border-[#363636] border-b text-white">
        <div className="flex items-center">
          <svg
            className="h-8 w-8 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L2 7L12 12L22 7L12 2Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 17L12 22L22 17"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12L12 17L22 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h1 className="text-xl font-bold">
            Fanzzy<span className="text-pink-500">.AI</span>
          </h1>
        </div>
        {
          Object.keys(userInfo).length > 0
            ? <div
                className="flex items-center space-x-2 cursor-pointer" 
              >
                <Avatar 
                  src={userInfo.avatar} 
                  style={{ cursor: 'pointer' }}
                  onClick={handleMenuOpen} 
                />
                <div onClick={handleMenuOpen}  className="hidden lg:flex lg:items-center">
                  <span className="ml-2 text-sm font-semibold leading-6 text-white" aria-hidden="true">My Profile</span>
                  <svg className="ml-2 h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd"></path>
                  </svg>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  onMouseLeave={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiMenu-list': {
                      backgroundColor: '#131313',
                      color: 'white',
                      width: '100px',
                    },
                  }}
                >
                  <MenuItem onClick={logout}>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            :
            <div className="flex items-center space-x-2">
              <Login ref={loginDialog} callSignup={callSignup} />
              <Signup ref={signupDialog} callLogin={callLogin} />
              <Button
                variant="outlined"
                sx={{ border: '1px solid rgb(219 39 119)' }}
                className="text-white hover:bg-pink-600 hover:text-white"
                onClick={() => loginDialog.current.open()}
              >
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => signupDialog.current.open()}
                sx={{ color: 'white' }}
                className="bg-pink-600 hover:bg-pink-700 hover:text-white">
                Create Free Account
                </Button>
            </div>
        }
      </header>
    </>
  )
}