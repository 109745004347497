import { useEffect } from 'react'
import {
  Compass,
  MessageCircle,
  Users,
  Sparkles,
  Bell,
  User,
  MoreHorizontal,
} from 'lucide-react'

export default function Sidebar(props) {
  const { isMobile, from } = props
  const navItems = [
    { name: 'Explore', icon: Compass },
    { name: 'Chat', icon: MessageCircle },
    { name: 'Subscriptions', icon: Users },
    { name: 'Create My Avatar', icon: Sparkles },
    { name: 'Notifications', icon: Bell },
    { name: 'My Profile', icon: User },
    { name: 'More', icon: MoreHorizontal },
  ]
  return (
    <aside
      className={`bg-[#131313] p-4 pr-6 top-20 h-full overflow-y-auto  transition-all duration-300 ${from !== 'chat' ? 'fixed' : ''} border-[#363636] border-r`}
    >
      <nav className="space-y-4">
        {navItems.map(item => {
          const Icon = item.icon
          return (
            <div key={item.name} className="flex items-center text-sm font-medium  h-[40px] w-full hover:bg-zinc-700 rounded-[10px] border border-white border-opacity-10 justify-start px-3 cursor-pointer">
              <Icon className="h-5 w-5 text-white" />
              {!isMobile && <span className="ml-3">{item.name}</span>}
            </div>
          )
        })}
      </nav>
    </aside>
  )
}