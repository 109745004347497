import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

let show = null
const Notification = () => {
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  show = (message, severity = 'info') => {
    setNotification({ open: true, message, severity })
  }
  const close = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }
  useEffect(() => {
    console.log(notification)
  }, [notification])

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={3000} // 自动隐藏时间：3秒
      onClose={close}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // 提示位置
      sx={{
        zIndex: 99999,
      }}
    >
      <Alert
        onClose={close}
        severity={notification.severity}
        sx={{
          width: '100%',
          color: 'white',
          backgroundColor: 'rgb(219 39 119)',
          '& .MuiAlert-icon': {
            color: 'white', // 设置图标颜色
          },
        }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}
// 静态方法暴露给外部
Notification.open = (message, severity) => {
  if (show) {
    show(message, severity)
  }
}

export default Notification
