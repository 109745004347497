import { Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useState } from 'react'
import dayjs from 'dayjs'
const StepOne = ({ getChoosenInfo }) => {
  const [birthday, setBirthday] = useState(null)
  const choosenInfo = val => {
    const birthday = dayjs(val).format('YYYY-MM-DD')
    getChoosenInfo({ age: birthday })
    setBirthday(val)
  }
  return (
    <>
      <Typography variant="h4" gutterBottom>When's your birthday?</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={birthday}
          onChange={choosenInfo}
          sx={{ 
            width: '100%',
            '& .MuiInputBase-root': {
              color: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            '& .MuiIconButton-root': {
              color: '#fff',
            }
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default StepOne