import { useState } from 'react'
import RecommendedList from '../List'

const Login = () => {
  return (
    <div className="mt-px-4 py-4 md:py-6 min-h-[400px]">
      <RecommendedList />
    </div>
  )
}

export default Login
