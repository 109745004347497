import './App.css';
import { useRoutes } from 'react-router-dom'
import Routes from './router'

function App() {
	const element = useRoutes(Routes)

	return (
		<div style={{ width: '100%', height: '100%' }} className="App">
			{element}
		</div>
	)
}

export default App;
