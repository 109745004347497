import { useState, useEffect } from 'react'
import { 
  Typography,
  Stack,
  Box,
  TextField
} from '@mui/material'
import { styled } from '@mui/material/styles'

// 自定义输入框样式
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  }
})
const StepThree = ({ getChoosenInfo }) => {
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    getChoosenInfo(userInfo)
  }, [userInfo])

  const handleChange = (e) => {
    const { name, value } = e.target
    const flag = name === 'username' ? '@' : ''
    const regText = `[^a-zA-Z0-9${flag}]`
    const reg = new RegExp(regText, 'g')
    const result = value.replace(reg, '')
    setUserInfo({ ...userInfo, [name]: result })
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>Set up your profile</Typography>
      <Stack spacing={3}>
        <Box>
          <Typography gutterBottom>Display Name</Typography>
          <StyledTextField  
            fullWidth
            value={userInfo.displayname}
            name="displayname"
            onChange={handleChange}
            placeholder="Enter your display name"
          />
        </Box>
        <Box>
          <Typography gutterBottom>Username</Typography>
          <StyledTextField
            fullWidth
            value={userInfo.username}
            name="username"
            onChange={handleChange}
            placeholder="@user"
          />
        </Box>
      </Stack>
    </>
  )
}

export default StepThree