import { useState } from 'react'
import { Check, ChevronDown } from 'lucide-react'

const NoLogin = () => {
  const [avatars, setAvatars] = useState([
    {
      id: 1,
      name: 'Alyssa',
      username: 'alyssa_cali',
      age: 19,
      gender: 'F',
      bio: 'Born and raised in California, Alyssa is a 19-year-old beach lover and aspiring actress.',
      instagram: 'alyssa_cali',
      verified: true,
      isNew: true,
      isHot: false,
      interests: ['Acting', 'Beach', 'Fashion'],
    },
    {
      id: 2,
      name: 'Savannah',
      username: 'savannah_pom',
      age: 19,
      gender: 'F',
      bio: 'Savannah is a 19-year-old freshman college pom-pom girl with a passion for dance and sports.',
      instagram: 'savannah_pom',
      verified: false,
      isNew: false,
      isHot: true,
      interests: ['Dance', 'Sports', 'College Life'],
    },
    {
      id: 3,
      name: 'Selene',
      username: 'selene_goth',
      age: 19,
      gender: 'F',
      bio: 'An alternative goth girl who recently dropped out of art school to pursue her passion in music.',
      instagram: 'selene_goth',
      verified: true,
      isNew: true,
      isHot: true,
      interests: ['Music', 'Gothic Culture', 'Art'],
    },
    {
      id: 4,
      name: 'Agnes',
      username: 'agnes_thrill',
      age: 24,
      gender: 'F',
      bio: 'Sister Agnes Therese, formerly a thrill-seeker who found her calling in the convent.',
      instagram: 'agnes_thrill',
      verified: false,
      isNew: false,
      isHot: false,
      interests: ['Religion', 'Adventure', 'Spirituality'],
    },
  ])
  
  const [displayedAvatars, setDisplayedAvatars] = useState(20)
  const [selectedGender, setSelectedGender] = useState('All')
  const [selectedInterests, setSelectedInterests] = useState([])
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)
  const allInterests = Array.from(
    new Set(avatars.flatMap(avatar => avatar.interests))
  )
  const filteredAvatars = avatars.filter(
    avatar =>
      (selectedGender === 'All' || avatar.gender === selectedGender) &&
      (selectedInterests.length === 0 ||
        selectedInterests.some(interest => avatar.interests.includes(interest)))
  )

  const toggleFilter = () => {
    setIsFilterExpanded(!isFilterExpanded)
  }
  return (
    <>
      {/* Explore Section */}
      <section className="p-8">
        
        {/* Filtering options */}
        <div className="mb-6">
          <div className="flex items-center space-x-4 mb-2">
            <button
              onClick={toggleFilter}
              className="flex items-center justify-between text-white hover:text-gray-300 transition-colors duration-200"
            >
              <span>Gender</span>
              <ChevronDown
                className={`ml-2 h-4 w-4 transform transition-transform ${
                  isFilterExpanded ? 'rotate-180' : ''
                }`}
              />
            </button>
            <button
              onClick={toggleFilter}
              className="flex items-center justify-between text-white hover:text-gray-300 transition-colors duration-200"
            >
              <span>Interests</span>
              <ChevronDown
                className={`ml-2 h-4 w-4 transform transition-transform ${
                  isFilterExpanded ? 'rotate-180' : ''
                }`}
              />
            </button>
          </div>
          {isFilterExpanded && (
            <div className="bg-gray-800 p-4 rounded-md mt-2">
              <div className="mb-4">
                <label
                  htmlFor="gender-filter"
                  className="block text-sm font-medium text-gray-400 mb-1"
                >
                  Gender
                </label>
                <select
                  id="gender-filter"
                  value={selectedGender}
                  onChange={e => setSelectedGender(e.target.value)}
                  className="bg-gray-700 text-white rounded-md px-3 py-2 w-full"
                >
                  <option value="All">All</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Interests
                </label>
                <div className="flex flex-wrap gap-2">
                  {allInterests.map(interest => (
                    <button
                      key={interest}
                      onClick={() =>
                        setSelectedInterests(prev =>
                          prev.includes(interest)
                            ? prev.filter(i => i !== interest)
                            : [...prev, interest]
                        )
                      }
                      className={`text-sm px-2 py-1 rounded ${
                        selectedInterests.includes(interest)
                          ? 'text-pink-500 font-semibold'
                          : 'text-gray-300 hover:text-white'
                      }`}
                    >
                      {interest}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredAvatars.slice(0, displayedAvatars).map(avatar => (
            <div
              key={avatar.id}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex flex-col"
            >
              <div className="relative">
                <img
                  src="/placeholder.svg?height=300&width=400"
                  alt={avatar.name}
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-2 left-2">
                  {avatar.isNew ? (
                    <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">
                      New
                    </div>
                  ) : avatar.isHot ? (
                    <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">
                      Hot
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="p-4 flex-grow">
                <div className="flex items-center mb-1">
                  <h4 className="text-lg font-semibold mr-1">
                    {avatar.name}
                  </h4>
                  {avatar.verified && (
                    <Check className="h-4 w-4 text-blue-500" />
                  )}
                </div>
                <p className="text-gray-400 text-sm">@{avatar.username}</p>
                <div className="flex items-center text-gray-300 text-sm mt-2">
                  <span className="mr-2">{avatar.age}</span>
                  <span>{avatar.gender}</span>
                </div>
                <p className="text-sm mt-2 mb-4">{avatar.bio}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {avatar.interests.map(interest => (
                    <span
                      key={interest}
                      className="bg-gray-700 text-xs text-gray-300 px-2 py-1 rounded"
                    >
                      {interest}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        {displayedAvatars < filteredAvatars.length && (
          <div className="mt-8 text-center"></div>
        )}
      </section>
    </>
  )
}

export default NoLogin
