import { useState, useEffect } from 'react'
import { 
  Typography,
  Button,
  Box,
  Stack,
  TextField
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { register } from '@/service/api'
import Notification from '@/components/Alert'
import { addUserInfo } from '@/store/loginUserInfo'
import { useDispatch } from 'react-redux'

// 验证码输入框样式
const VerificationBox = styled(TextField)({
  width: '50px',
  '& input': {
    textAlign: 'center',
    fontSize: '24px',
    color: '#fff'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  }
})

const Verify = (props) => {
  const { signupInfo, resendEmail, registerSuccess } = props
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''))
  const dispatch = useDispatch()

  const handleVerificationChange = async (index, value) => {
    const newCode = [...verificationCode]
    newCode[index] = value
    setVerificationCode(newCode)
    
    // 自动跳转到下一个输入框
    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`)?.focus()
    }
  }
  useEffect(() => {
    const handlePaste = async (event) => {
      const text = await navigator.clipboard.readText()
      if (/^\d{6}$/.test(text)) {
        const codes = text.split('')
        setVerificationCode(codes)
        document.getElementById(`code-5`)?.focus()
      }
    }
    document.getElementById(`code-0`)?.focus()
    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [])

  const handleResendEmail = () => {
    resendEmail()
  }

  const registerUser = async () => {
    const params = {
      ...signupInfo,
      username: signupInfo.email,
      verification_code: verificationCode.join('')
    }
    if (params.verification_code.length !== 6) {
      Notification.open('Verification code is error', 'warning')
      return
    }
    try {
      const res = await register(params)
      registerSuccess()
      if (res?.data) {
        dispatch(addUserInfo(res?.data?.user))
        registerSuccess()
      }
    } catch (error) {
      console.log(error)
      Notification.open('Signup failed', 'warning')
    }
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>Fanzzy.AI</Typography>
      <Typography gutterBottom>
        Enter the code sent to {signupInfo.email}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center" mb={2}>
        {verificationCode.map((digit, index) => (
          <VerificationBox
            key={index}
            id={`code-${index}`}
            value={digit}
            onChange={(e) => handleVerificationChange(index, e.target.value)}
            inputProps={{ maxLength: 1 }}
          />
        ))}
      </Stack>
      <Button 
        variant="contained" 
        fullWidth
        sx={{ 
          mt: 2, 
          backgroundColor: '#1a1a1a',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#2a2a2a'
          }
        }}
        onClick={registerUser}
      >
        Verify
      </Button>
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography component="span" sx={{ color: 'rgba(255,255,255,0.7)' }}>
          Didn't receive the code? 
        </Typography>
        <span onClick={handleResendEmail} className="cursor-pointer text-pink-500 pl-2 hover:text-pink-700">
          Resend email
        </span>
      </Box>
    </>
  )
}

export default Verify
