import React, { useState, useEffect } from 'react'
import { Typography, Stack, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// 自定义按钮样式
const StyledButton = styled(Button)({
  backgroundColor: '#fff',
  color: '#ff69b4',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  width: '100%',
  padding: '10px',
  marginBottom: '10px',
})

const GenderPreferences = ({ getChoosenInfo }) => {
  const [gender, setGender] = useState('')
  const [interest, setInterest] = useState('')
  const setSelectedStyle = (type, key) => {
    return {
      backgroundColor: type === key  ? '#ff69b4' : 'none',
      color: type === key ? '#fff' : '#ff69b4',
      '&:hover': {
        backgroundColor: type === key ? '#ff69b4' : 'none',
        color: type === key ? '#fff' : '#ff69b4',
      }
    }
  }
  useEffect(() => {
    getChoosenInfo({
      gender,
      interest
    })
  }, [gender, interest])
  return (
    <Box sx={{ backgroundColor: '#1a1a2e', padding: '20px', borderRadius: '8px' }}>
      <Stack spacing={2}>
        <Typography variant="subtitle1" color="#fff">
          Your Gender
        </Typography>
        <Stack direction="row" spacing={2}>
          <StyledButton
            sx={setSelectedStyle('Male', gender)}
            onClick={() => setGender('Male')}
          >
            Male
          </StyledButton>
          <StyledButton
            sx={setSelectedStyle('Female', gender)}
            onClick={() => setGender('Female')}
          >
            Female
          </StyledButton>
          <StyledButton
            sx={setSelectedStyle('Other', gender)}
            onClick={() => setGender('Other')}
          >
            Other
          </StyledButton>
          <StyledButton
            sx={setSelectedStyle('Prefer not to say', gender)}
            onClick={() => setGender('Prefer not to say')}
          >
            Prefer not to say
          </StyledButton>
        </Stack>
        <Typography variant="subtitle1" color="#fff">
          You Are Interested In
        </Typography>
        <Stack direction="row" spacing={2}>
          <StyledButton
            sx={setSelectedStyle('Male', interest)}
            onClick={() => setInterest('Male')}
          >
            Male
          </StyledButton>
          <StyledButton
            sx={setSelectedStyle('Female', interest)}
            onClick={() => setInterest('Female')}
          >
            Female
          </StyledButton>
          <StyledButton
            sx={setSelectedStyle('Everyone', interest)}
            onClick={() => setInterest('Everyone')}
          >
            Everyone
          </StyledButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default GenderPreferences