import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { 
  Dialog,
  styled,
  IconButton
} from '@mui/material'
import { useEffect } from 'react'
import Email from './Step/email'
import CloseIcon from '@mui/icons-material/Close'
import Verify from './Step/Verify'
import Guide from '@/components/Guide'

// 自定义模糊弹窗
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(26, 31, 46, 0.8)',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: theme.spacing(2),
    maxWidth: '500px',
    width: '100%',
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    color: '#fff',
    overflow: 'visible'
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  }
}))

const SignupDialog = forwardRef((props, ref) => {
  const { callLogin } = props

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false)
  }))
  const emailRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const [signupInfo, setSignupInfo] = useState({
    email: '',
    password: ''
  })
  const guideDialog = useRef(null)
  const [showGuide, setShowGuide] = useState(false)
  const callVerify = (email, password) => {
    setSignupInfo({ email, password })
    setShowVerify(true)
  }

  const handleClose = () => {
    setOpen(false)
    setShowVerify(false)
  }

  const resendEmail = () => {
    emailRef.current.resend()
  }

  const registerSuccess = () => {
    setShowGuide(true)
  }

  return (
    <BlurDialog 
      open={open} 
      onClose={handleClose}
      fullWidth
    >
      <IconButton
        sx={{ position: 'absolute', right: 8, top:  '-41px' }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
      {
        showGuide ? <Guide ref={guideDialog} /> : showVerify
        ? <Verify
            signupInfo={signupInfo}
            resendEmail={resendEmail}
            registerSuccess={registerSuccess}
          />
        : <Email
            ref={emailRef}
            callLogin={callLogin}
            callVerify={callVerify}
          />
      }
    </BlurDialog>
  )
})

export default SignupDialog