import { 
  Dialog, 
  DialogContent, 
  TextField, 
  Button, 
  IconButton, 
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useState,  useImperativeHandle, forwardRef } from 'react'
import GoogleLogin from '@/components/GoogleLogin'
import { login } from '@/service/api'
import { addUserInfo, changeIfGuest } from '@/store/loginUserInfo'
import { useDispatch, useSelector } from 'react-redux'
// 自定义样式的Dialog
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(28, 28, 28, 0.95)',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)', // 给对话框背后的内容也添加模糊效果
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}))

// 自定义按钮样式
const SocialButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '10px',
  width: '100%',
  textTransform: 'none',
  marginBottom: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.1)',
}))

const LoginDialog = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false)
  }))

  const { callSignup } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const onClose = () => {
    setOpen(false)
  } 
  const loginApp = async () => {
    const res = await login({ email, password })
    if (res?.data) {
      dispatch(addUserInfo(res?.data.user))
      dispatch(changeIfGuest(false))
      localStorage.setItem('TOKEN', res?.data?.access_token)
      onClose()
    }
  }

  return (
    <BlurDialog open={open} onClose={onClose}>
      
      <IconButton
        sx={{ position: 'absolute', right: 8, top: 8 }}
        onClick={onClose}
      >
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>

      <DialogContent>
        <Typography
          variant="h4"
          sx={{ mb: 4, color: 'white', fontWeight: 'bold' }}
        >
          Sign in
        </Typography>

        <TextField
          fullWidth
          placeholder="E-mail"
          variant="outlined"
          onChange={e => setEmail(e.target.value)}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
            },
            '& input': { color: 'white' }
          }}
        />

        <TextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
            },
            '& input': { color: 'white' }
          }}
        />

        <Typography 
          variant="body2" 
          sx={{ mb: 3, color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
        >
          Forgot password?
        </Typography>

        <Button
          fullWidth
          variant="contained"
          sx={{ mb: 4, fontWeight: 'bold' }}
          onClick={loginApp}
        >
          Sign in
        </Button>

        <div className="flex items-center gap-4 mb-6">
          <div
            className="flex-1 h-[1px]" 
            style={{ background: "linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)" }}
          ></div>
          <div className="font-extrabold text-white">or sign in with</div>
          <div
            className="flex-1 h-[1px]"
            style={{ background: "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%)"}}>
          </div>
        </div>
        <GoogleLogin />
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography sx={{ color: 'white', display: 'inline', fontWeight: 'bold' }}>
            Don't have an account yet?{' '}
          </Typography>
          <Typography
            component="span"
            sx={{ color: '#E91E63', cursor: 'pointer' }}
            onClick={callSignup}
          >
            Sign up
          </Typography>
        </Box>
      </DialogContent>
    </BlurDialog>
  )
})

export default LoginDialog