import React from 'react'
import Index from '../pages/index/index'
import List from '../pages/list'
import Chat from '../pages/chat'
const routes = [
	{
		path: `/`,
		element: <Index />,
	},
	{
		path: `/list`,
		element: <List />,
	},
	{
		path: `/chat`,
		element: <Chat />,
	},
]

export default routes
