import { useState, useEffect } from 'react'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import NoLogin from './components/NoLogin'
import { loginUserInfo } from '@/store/loginUserInfo'
import { useSelector } from 'react-redux'
import Footer from '@/components/Footer'
import Login from './components/Login'

export default function HomePage() {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  const loginInfo = useSelector(loginUserInfo)
  const { isGuest, isLoading } = loginInfo

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1200)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="flex flex-col min-h-screen bg-[#131313] text-white">      
      <Header />
      <div className="flex flex-1 mt-24">
        <Sidebar isMobile={isMobile} />
        <main className={`flex-1 ${isMobile ? 'mx-16' : 'ml-64'}`}>
          <section className="bg-gradient-to-r from-purple-900 to-pink-900 p-12 text-center relative mt-1">
            <div className="relative z-10">
              <h2 className="text-4xl font-bold mb-4">
                Connect with AI Friends on Fanzzy.AI
              </h2>
              <p className="mb-8">
                Experience the future of social networking with AI-powered
                companions
              </p>
            </div>
          </section>
          <h3 className="text-2xl font-bold my-6 text-left">Explore AI Companions</h3>
          <div className="mb-8 mr-8">
            {isLoading ? (
              <div className="px-4 py-4 md:py-6 min-h-[400px]">
                loading...
              </div>
            ) : isGuest ? <NoLogin /> : <Login />}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  )
}
