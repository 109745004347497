import { useEffect, useState } from 'react'
import { getRecommendedUsers, followUser } from '@/service/api'
import { useSelector } from 'react-redux'
import { loginUserInfo } from '@/store/loginUserInfo'
import { Avatar } from '@mui/material'

const RecommendedUsers = () => {
  const loginInfo = useSelector(loginUserInfo)
  const { userInfo } = loginInfo
  const [users, setUsers] = useState([])
  useEffect(() => {
    const start = async () => {
      const res = await getRecommendedUsers(10)
      if (res?.data) {
        setUsers(res.data)
      }
    }
    start()
  }, [])

  const handleFollow = (ids) => {
    followUser({ id: userInfo.id, follow_ids: ids })
  }

  const followAll = () => {
    handleFollow(users.map(user => user.id))
  }

  return (
    <div className="bg-gray-800 p-5 rounded-lg max-h-[500px] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-white text-lg">Recommended Users</h2>
      </div>
      {users.map((user, index) => (
        <div key={index} className="bg-gray-700 p-4 mb-3 rounded-lg flex justify-between items-center">
          <div className="flex items-center">
            <Avatar src={user.avatar} className="bg-gray-600 w-10 h-10 rounded-full mr-3"></Avatar>
            <div>
              <h3 className="text-white">{user.displayname}</h3>
              <p className="text-gray-400">
                {user.follows} followers • {user.stars ? user.stars : 0} stars
              </p>
              <div className="flex space-x-2 mt-1">
                {user.preference?.map((tag, i) => (
                  <span key={i} className="text-pink-500 text-xs py-1 rounded bg-slate-600 px-2">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <button onClick={() => handleFollow([user.id])} className="text-pink-500">Follow</button>
        </div>
      ))}
    </div>
  )
}

export default RecommendedUsers