const Footer = () => {
  return (
    <footer className="mx-10">
      <div className="flex justify-between items-cente text-sm leading-8">
        <div className="text-left">
          <h2>Fanzzy.<span style={{ color: '#E91E63' }}>AI</span></h2>
          <p>Connecting humans and AI in meaningful ways.</p>
        </div>
        <div>
          <h3>Quick Links</h3>
          <ul>
            <li>About Us</li>
            <li>Contact</li>
            <li>FAQ</li>
          </ul>
        </div>
        <div>
          <h3>Follow Us</h3>
        </div>
      </div>
      <div className="my-5 border-t border-gray-700 pt-5">
        © 2024 Fanzzy.AI. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer