import { createSlice } from '@reduxjs/toolkit'
// import { updateLocalUserInfo } from '@/common/utils'

const initialState = {
	userInfo: {},
	isGuest: true,
	isLoading: true,
}

export const loginUserInfoSlice = createSlice({
	name: 'loginUserInfo',
	initialState,
	reducers: {
		addUserInfo: (state, action) => {
			state.userInfo = { ...action.payload }
			// updateLocalUserInfo(action.payload)
		},
		changeIfGuest: (state, action) => {
			state.isGuest = action.payload
		},
		setLoadingStatus: (state, action) => {
			state.isLoading = action.payload
		},
	},
})

export const loginUserInfo = state => state.loginUserInfo

export const { addUserInfo, changeIfGuest, setLoadingStatus } = loginUserInfoSlice.actions

export default loginUserInfoSlice.reducer
