
// import classNames from 'classnames/bind'

// const cx = classNames.bind(styles)

const Dwaves = () => {
	return (
		<div className='p-7'>list</div>
	)
}
export default Dwaves
