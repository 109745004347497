import React, { useState } from 'react'
import { Avatar, IconButton, Typography, Button, TextField, Box } from '@mui/material'
import { Menu, Send, Settings, Favorite } from '@mui/icons-material'
import Sidebar from '@/components/Sidebar'
import Header from '@/components/Header'
import styles from './index.module.css'
const ChatApp = () => {
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([
    { id: 1, text: 'Hello there, My name is Leonie. What brings you here today?', type: 'reply' },
    { id: 2, text: 'Is everything OK?', type: 'send' },
    { id: 3, text: 'Very good. And you?', type: 'send'},
  ])

  const handleSendMessage = () => {
    if (message.trim()) {
      setMessages([...messages, { id: Date.now(), text: message, sender: 'Steven' }])
      setMessage('')
    }
  }

  return (
    <>
      <Header />
      <Box className="flex h-screen bg-[#131313] text-gray-100 text-left pt-20">
        {/* Sidebar */}
      <Sidebar isMobile={true} from="chat" />
      <Box className="p-6 bg-[#1A1A1A] flex flex-col space-y-4 w-80">
        <Typography variant="h6" className="text-white">
          Chat
        </Typography>
        <Box className="overflow-y-auto space-y-4">
          <Box className="w-full mt-2 flex bg-[#303030] border border-zinc-600 rounded-[10px] items-start p-2">
            <Avatar src="https://via.placeholder.com/40" />
            <Box className="fflex-col ml-3 w-44 justify-start items-start">
              <Typography className="text-white text-xs">Leonie Schäfer</Typography>
              <Typography className="text-xs text-gray-400 truncate">Hey, you! I'm LeonieLeonieLeonie</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Chat Section */}
      <Box className="flex-grow flex flex-col">
        {/* Chat Header */}
        <Box className="bg-[#131313] p-4 flex justify-between items-center">
          <Box className="flex items-center space-x-3">
            <Avatar src="https://via.placeholder.com/40" />
            <Typography className="text-white" variant="h6">
              Leonie
            </Typography>
          </Box>
          <Box>
            <Typography className="text-gray-400 text-sm">Lvl 12</Typography>
          </Box>
        </Box>

        {/* Chat Messages */}
        <Box className="flex-grow p-4 overflow-y-auto space-y-7">
          {messages.map((msg) => (
            <Box
              key={msg.id}
              className={`flex ${msg.type === "send" ? "justify-end" : "justify-start"}`}
            >
             {
                msg.type === "reply" && 
                <Avatar
                  className="mr-3"
                  sx={{ width: 30, height: 30 }}
                  src="https://via.placeholder.com/40"
                />
              }
              <Box
                className={`max-w-xs p-3 text-white rounded-lg ${
                  msg.type === "send" ? "bg-[#ad8b25] rounded-br-none" : "bg-[#343434] rounded-bl-none"
                }`}
              > 
                {msg.text}
              </Box>
              {
                msg.type === "send" && 
                <Avatar
                  className="ml-3"
                  sx={{ width: 30, height: 30 }}
                  src="https://via.placeholder.com/40"
                />
              }
            </Box>
          ))}
        </Box>

        {/* Input Box */}
        <Box className="p-4 flex items-center space-x-3">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="bg-[#252525] rounded-lg text-white"
            InputProps={{
              style: { color: "white" },
            }}
          />
          <IconButton color="primary" onClick={handleSendMessage}>
            <Send />
          </IconButton>
        </Box>
        </Box>

      {/* Right Panel */}
        <Box className={styles.chatRight}>
          <Avatar src="https://via.placeholder.com/80" className="mx-auto" />
          <Typography variant="h6" className="text-white text-center">
            Leonie
          </Typography>
          <Typography variant="body2" className="text-gray-400 text-center">
            @Leonie Lucca
          </Typography>
          <Box className="flex justify-center items-center space-x-2">
            <Favorite color="error" />
            <Typography className="text-gray-400 text-sm">1.4M</Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            className="bg-pink-600 hover:bg-pink-700"
          >
            Subscribe
          </Button>
          <Typography className="text-gray-400">Tags</Typography>
          <Box className={styles.userTags}>
            <span>OC</span>
            <span>Anime</span>
            <span>Movies & TV</span>
          </Box>
          <div className="absolute top-0 right-4">
            <Settings className='text-white' />
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ChatApp
