import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { 
  Dialog,
  Box,
  Typography,
  Button,
  styled,
  IconButton
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import StepOne from './Step/One'
// import StepTwo from './Step/Two'
import StepThree from './Step/Three'
import CloseIcon from '@mui/icons-material/Close'
import StepFour from './Step/Four'
import StepFive from './Step/Five'
import StepSix from './Step/Six'
import { saveUserPreference } from '@/service/api'
import { useSelector } from 'react-redux'
import { loginUserInfo } from '@/store/loginUserInfo'
import Notification from '@/components/Alert'

// 自定义模糊弹窗
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(26, 31, 46, 0.8)',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: theme.spacing(2),
    maxWidth: '600px',
    width: '100%',
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    color: '#fff',
    overflow: 'visible'
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  }
}))

const GuideDialog = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close
  }))

  const [activeStep, setActiveStep] = useState(0)
  const [open, setOpen] = useState(false)
  const loginInfo = useSelector(loginUserInfo)
  const { userInfo } = loginInfo
  const [choosenInfo, setChoosenInfo] = useState({
    displayname: userInfo.email?.split('@')[0],
    username: '',
    male: '0',
    preferencegender: 'all'
  })

  const close = () => {
    setOpen(false)
    setActiveStep(0)
  }
  
  const handleBack = () => {
    setActiveStep((prev) => prev - 1)
  }
  const infoMap = {
    0: ['age'],
    1: ['displayname', 'username']
  }
  const calculateAge = (birthDate) => {
    const today = new Date()
    const birth = new Date(birthDate)
    let age = today.getFullYear() - birth.getFullYear()
    const monthDifference = today.getMonth() - birth.getMonth()

    // 如果当前月份还没到出生月份，或者到了出生月份但还没到出生日期，则年龄减一
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--
    }
    return age
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!choosenInfo.age) {
        Notification.open('Please enter your birthdate to continue.', 'warning')
        return
      }
      if (choosenInfo.age) {
        const age = calculateAge(choosenInfo.age)
        if (age < 18) {
          Notification.open('You must be at least 18 years old to continue.', 'warning')
          return
        }
      }
    }
    const info = infoMap[activeStep]
    let [isChoosen, name] = [true, '']
    if (Array.isArray(info)) {
      info.forEach(item => {
        if (!choosenInfo[item]) {
        isChoosen = false
          name = item
        }
      })
    }
    if (!isChoosen) {
      Notification.open(`Please choose your ${name}`, 'warning')
      return
    }
    saveUserPreference({ ...choosenInfo, id: userInfo.id })
    setActiveStep((prev) => prev + 1)
  }
 
  const getChoosenInfo = info => {
    setChoosenInfo({ ...choosenInfo, ...info })
  }
  useEffect(() => {
    console.log(choosenInfo)
  }, [choosenInfo])

  const components = [
    <StepOne getChoosenInfo={getChoosenInfo} />,
    <StepThree getChoosenInfo={getChoosenInfo} />,
    <StepFour getChoosenInfo={getChoosenInfo} />,
    <StepFive getChoosenInfo={getChoosenInfo} />,
    <StepSix getChoosenInfo={getChoosenInfo} />
  ]
  const renderStep = () => {
    return components[activeStep] || null
  }

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography>
          {activeStep + 1}/5
        </Typography>
        {activeStep > 0 && (
          <IconButton 
            onClick={handleBack} 
            sx={{ color: '#ff69b4' }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </Box>
      {renderStep()}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        {activeStep > 0 ? (
          <Button
            variant="text"
            onClick={() => setActiveStep((prev) => prev + 1)}
            sx={{ color: '#fff' }}
        >
            Skip
          </Button>
        ) : <span />}
        <Button
          variant="contained"
            onClick={handleNext}
            sx={{ 
              backgroundColor: '#ff69b4',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#ff4da6'
              }
            }}
          >
            {activeStep < 4 ? 'Next': 'Done' }
          </Button>
      </Box>
    </Box>
  )
})

export default GuideDialog